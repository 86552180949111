body {
    background: #050504 !important;
}


.brandmark {
    max-height: 50px;
}

.nav-bar a.active {
    background: #222;
    border-radius: 5px;
    color: #fff;
}

.connect-wallet-btn {
    background: none;
}

.breadcrumb-container {
    margin-top: 50px;
}

#swap-page {
    /* border: 1px solid #727d85; */
    background: rgba(0,0,0,0.7);
    padding: 20px;
}


.chart-container-bg {
    background: rgba(0,0,0,0.7);
    border-radius: 20px;
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.9);
}

.choose-token .token-symbol-container{
    font-size: 13px;
    font-weight: 600;
}

.degen-balance-text {
  position: absolute;
  right: 0;
}
.open-currency-select-button {
  background: #222 !important;
  border-radius: 2px;
  margin-left: 0px;
  padding: 8px 12px !important;
  border-radius: 3px !important;
  top: 12px;
}

.token-symbol-container {


}

input.token-amount-input {
  text-align: right;
  font-size: 24px;
}

#swap-button {
  margin-top: 20px;
  color: #222;
}

#swap-button div {
  font-size: 16px;
}

.swap-currency-btn {
  background: none;
  border-radius: 4px;
  border: 2px solid #222;
  border-radius: 50%;
  background: #151515;
}